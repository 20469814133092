import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatmb',
  templateUrl: './chatmb.component.html',
  styleUrls: ['./chatmb.component.scss'],
})
export class ChatmbComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.addScript();
    }, 10000);
  }

  public addScript(): void {
    const script = document.createElement('script');
    script.src = `assets/chat/chatMB.js`;
    document.head.appendChild(script);
  }
}
