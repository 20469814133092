import { Component, OnInit } from '@angular/core';
import { PlanDto } from '@app/@core/api/models';
import { TravelInsurancePlanService } from '@app/@core/api/services';
import { StorageService } from '@app/@core/services';
import { environment } from '@env/environment';
import { finalize, map } from 'rxjs';
@Component({
  selector: 'app-info-plans',
  templateUrl: './info-plans.component.html',
  styleUrls: ['./info-plans.component.scss'],
})
export class InfoPlansComponent implements OnInit {
  responsiveOptions;
  public loading: boolean;
  public plans: PlanDto[];

  constructor(private _plan: TravelInsurancePlanService, private _storage: StorageService) {
    this.responsiveOptions = [
      {
        breakpoint: '1460px',
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: '1366px',
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: '1100px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
        showNavigators: true,
        showIndicators: true,
      },
    ];
    this.plans = [];
    this.loading = true;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this._plan
      .GetPlans(environment.tenantId, false, this._storage.getTopLevelDomain())
      .pipe(
        finalize(() => {
          this.loading = false;
          console.log(this.plans);
        }),
        map((plan) => plan.filter((plan) => plan.IsDeleted == false)),
        map((filteredPlans) => filteredPlans.filter((plan) => !plan.Name?.toLowerCase().includes('nacional'))),
        map((filteredPlans) => filteredPlans.sort((a, b) => b.Weight - a.Weight)),
        map((sortedPlans, index) => sortedPlans.map((plan, index) => ({ ...plan, index }))),
        map((sortedPlans) => sortedPlans.slice(0, 4))
      )
      .subscribe((plans: any) => (this.plans = plans));
  }
}
