/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PerkQuoteRequestDto } from '../models/perk-quote-request-dto';
import { PerkQuoteResponseDto } from '../models/perk-quote-response-dto';
import { QuotationRequestDto } from '../models/quotation-request-dto';
import { QuotationResponseDto } from '../models/quotation-response-dto';
import { TravelInsuranceQuoteResponseDto } from '../models/travel-insurance-quote-response-dto';
import { GetTravelInsuranceRequestQuoteRequestDto } from '../models/get-travel-insurance-request-quote-request-dto';
import { TravelInsuranceCompleteInfoRequestDto } from '../models';
import { environment } from '@env/environment';
import { BaseResponse } from '../models/base/base-response.dto';

@Injectable({
  providedIn: 'root',
})
export class QuotationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  public TemporalQuote(body: QuotationRequestDto): Observable<any> {
    return this.http.post(`${this.rootUrl}/quoter/TemporalQuote`, body);
  }

  public UpdateQuotation(body: any): Observable<any> {
    return this.http.put(`${this.rootUrl}/Dashboard/TravelInsurance`, body);
  }
  /**
   * Path part for operation apiServicesAppQuotationQuotePost
   */
  static readonly ApiServicesAppQuotationQuotePostPath = '/quoter/Quote';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppQuotationQuotePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationQuotePost$Plain$Response(params: {
    Authentication: string;
    body?: QuotationRequestDto;
  }): Observable<StrictHttpResponse<QuotationResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, QuotationService.ApiServicesAppQuotationQuotePostPath, 'post');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<QuotationResponseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppQuotationQuotePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationQuotePost$Plain(params: {
    Authentication: string;
    body?: QuotationRequestDto;
  }): Observable<QuotationResponseDto> {
    return this.apiServicesAppQuotationQuotePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationResponseDto>) => r.body as QuotationResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppQuotationQuotePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationQuotePost$Json$Response(params: {
    Authentication: string;
    body?: QuotationRequestDto;
  }): Observable<StrictHttpResponse<QuotationResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, QuotationService.ApiServicesAppQuotationQuotePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<QuotationResponseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppQuotationQuotePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationQuotePost$Json(params: {
    Authentication: string;
    body?: QuotationRequestDto;
  }): Observable<QuotationResponseDto> {
    return this.apiServicesAppQuotationQuotePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<QuotationResponseDto>) => r.body as QuotationResponseDto)
    );
  }

  /**
   * Path part for operation apiServicesAppQuotationPerkQuotePost
   */
  static readonly ApiServicesAppQuotationPerkQuotePostPath = '/api/Quotation/PerkQuote';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppQuotationPerkQuotePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationPerkQuotePost$Plain$Response(params: {
    Authentication: string;
    body?: PerkQuoteRequestDto;
  }): Observable<StrictHttpResponse<PerkQuoteResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, QuotationService.ApiServicesAppQuotationPerkQuotePostPath, 'post');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PerkQuoteResponseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppQuotationPerkQuotePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationPerkQuotePost$Plain(params: {
    Authentication: string;
    body?: PerkQuoteRequestDto;
  }): Observable<PerkQuoteResponseDto> {
    return this.apiServicesAppQuotationPerkQuotePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PerkQuoteResponseDto>) => r.body as PerkQuoteResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppQuotationPerkQuotePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationPerkQuotePost$Json$Response(params: {
    Authentication: string;
    body?: PerkQuoteRequestDto;
  }): Observable<StrictHttpResponse<PerkQuoteResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, QuotationService.ApiServicesAppQuotationPerkQuotePostPath, 'post');
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PerkQuoteResponseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppQuotationPerkQuotePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppQuotationPerkQuotePost$Json(params: {
    Authentication: string;
    body?: PerkQuoteRequestDto;
  }): Observable<PerkQuoteResponseDto> {
    return this.apiServicesAppQuotationPerkQuotePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PerkQuoteResponseDto>) => r.body as PerkQuoteResponseDto)
    );
  }

  /**
   * Path part for operation apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet
   */
  static readonly ApiServicesAppQuotationGetTravelInsuranceRequestQuoteGetPath =
    '/quoter/GetTravelInsuranceRequestQuote';

  // apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Plain$Response(params: {
  //   HashCode?: string;
  //   Authentication: string;
  // }): Observable<StrictHttpResponse<TravelInsuranceQuoteResponseDto>> {
  //   const rb = new RequestBuilder(
  //     this.rootUrl,
  //     QuotationService.ApiServicesAppQuotationGetTravelInsuranceRequestQuoteGetPath,
  //     'get'
  //   );
  //   if (params) {
  //     rb.query('HashCode', params.HashCode, {});
  //     rb.header('Authentication', params.Authentication, {});
  //   }

  //   return this.http
  //     .request(
  //       rb.build({
  //         responseType: 'text',
  //         accept: 'text/plain',
  //       })
  //     )
  //     .pipe(
  //       filter((r: any) => r instanceof HttpResponse),
  //       map((r: HttpResponse<any>) => {
  //         return r as StrictHttpResponse<TravelInsuranceQuoteResponseDto>;
  //       })
  //     );
  // }

  // apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Plain(params: {
  //   HashCode?: string;
  //   Authentication: string;
  // }): Observable<TravelInsuranceQuoteResponseDto> {
  //   return this.apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Plain$Response(params).pipe(
  //     map((r: StrictHttpResponse<TravelInsuranceQuoteResponseDto>) => r.body as TravelInsuranceQuoteResponseDto)
  //   );
  // }

  // apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Json$Response(params: {
  //   Authentication: string;
  //   body?: GetTravelInsuranceRequestQuoteRequestDto;
  // }): Observable<StrictHttpResponse<TravelInsuranceQuoteResponseDto>> {
  //   const rb = new RequestBuilder(
  //     this.rootUrl,
  //     QuotationService.ApiServicesAppQuotationGetTravelInsuranceRequestQuoteGetPath,
  //     'get'
  //   );
  //   if (params) {
  //     rb.body(params.body, 'application/*+json');
  //   }

  //   return this.http
  //     .request(
  //       rb.build({
  //         responseType: 'json',
  //         accept: 'text/json',
  //       })
  //     )
  //     .pipe(
  //       filter((r: any) => r instanceof HttpResponse),
  //       map((r: HttpResponse<any>) => {
  //         return r as StrictHttpResponse<TravelInsuranceQuoteResponseDto>;
  //       })
  //     );
  // }

  // apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Json(params: {
  //   Authentication: string;
  //   body?: GetTravelInsuranceRequestQuoteRequestDto;
  // }): Observable<TravelInsuranceQuoteResponseDto> {
  //   return this.apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Json$Response(params).pipe(
  //     map((r: StrictHttpResponse<TravelInsuranceQuoteResponseDto>) => r.body as TravelInsuranceQuoteResponseDto)
  //   );
  // }

  apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Json(HashCode: string, params: string[]) {
    const defaultParams = { Customer: false, Plan: false, Benefit: false, Summary: false, ExchangeRate: false };
    let parameters = new HttpParams();

    for (const key in defaultParams) {
      parameters = parameters.set(key, params.includes(key));
    }

    return this.http.get<GetTravelInsuranceRequestQuoteRequestDto>(
      `${this.rootUrl}${QuotationService.ApiServicesAppQuotationGetTravelInsuranceRequestQuoteGetPath}/${environment.tenantId}/${HashCode}`,
      { params: parameters }
    );
  }

  //Quoatation B2B

  static readonly ApiServicesAppQuotationQuotationB2BPostPath = '/quoter/Quote';

  apiQuotationB2B(body: QuotationRequestDto): Observable<QuotationResponseDto> {
    return this.http.post<QuotationResponseDto>(
      `${this.rootUrl}${QuotationService.ApiServicesAppQuotationQuotationB2BPostPath}`,
      body
    );
  }

  static readonly ApiServicesAppQuotationGetTravelInsuranceRequestQuoteB2BGetPath =
    '/quoter/GetTravelInsuranceRequestQuote';

  apiGetTravelInsuranceRequestQuoteB2B(
    body: GetTravelInsuranceRequestQuoteRequestDto
  ): Observable<TravelInsuranceQuoteResponseDto> {
    return this.http
      .get<any>(
        `${this.rootUrl}${QuotationService.ApiServicesAppQuotationGetTravelInsuranceRequestQuoteB2BGetPath}/${body.TenantId}/${body.HashCode}?Customer=true&Plan=true&Benefit=true&Summary=true&ExchangeRate=true`
      )
      .pipe(map((q: BaseResponse<string>) => JSON.parse(q.result) as TravelInsuranceQuoteResponseDto));
  }

  static readonly ApiServicesAppQuotationSetCompleteInfoB2BGetPath = '/quoter/SetCompleteInfo';

  apiSetCompleteInfoB2B(body: TravelInsuranceCompleteInfoRequestDto): Observable<void> {
    return this.http.put<void>(
      `${this.rootUrl}${QuotationService.ApiServicesAppQuotationSetCompleteInfoB2BGetPath}`,
      body
    );
  }

  static readonly ApiServicesAppQuotationSendMailQuoteB2BGetPath = '/document/Documents/SendMailQuote/QuotationB2B';

  apiSendMailQuoteB2B(body: { HashCode: string }): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.rootUrl}${QuotationService.ApiServicesAppQuotationSendMailQuoteB2BGetPath}`,
      body
    );
  }

  getStatusQuote(HashCode: string) {
    return this.http.get<BaseResponse<boolean>>(`${this.rootUrl}/quoter/GetStatusQuote/${HashCode}`);
  }
}
