<div class="container-fluid bg-comments pt-3 pb-4">
  <p class="text-center text-blue-tas font-size-32 fw-bolder">¡Las experiencias hablan por nosotros!</p>
  <div class="center-img">
    <img
      class="mt-3"
      ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938271/TAS/home/calificacion-comments_dkdhfi_ycnsck.webp"
      alt="calificación"
      width="244.333"
      height="64.172"
      loading="lazy"
    />
  </div>
  <p class="font-size-18 font-size-md-20 font-size-lg-20 font-size-xl-20 text-md-center mt-3">
    En Traveler Assistance, encontrarás las mejores reseñas de asistencias de viajes a nivel mundial como una marca
    <b>TOP QUALITY.</b>
  </p>
  <div class="container mt-4">
    <div class="row d-none d-lg-flex">
      <div class="col-4">
        <div class="card-comments text-center mb-5">
          <img
            ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938274/TAS/home/claudia_xnggab_cqkpxa.webp"
            alt="imagen de perfil"
            width="96"
            height="96"
            loading="lazy"
          />
          <p class="text-blue-tas font-size-18 mb-0 fw-bolder">Claudia Monrroy</p>
          <p class="font-size-14 fw-bolder">Una buena experiencia</p>
          <p class="font-size-14 text-justify">
            Me contactaron de inmediato y Manuela Mosquera fue muy clara con la información. El pago fue fácil de hacer
            y los documentos llegaron rápidamente.
          </p>
          <img
            class="img-fluid w-100"
            ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938278/TAS/home/raiting-claudia_t64adh_b1aaft.webp"
            alt="calificacion"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="card-comments text-center mb-5">
          <img
            ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938276/TAS/home/laura_uckrjz_bjrexs.webp"
            alt="imagen de perfil"
            width="96"
            height="96"
            loading="lazy"
          />
          <p class="text-blue-tas font-size-18 mb-0 fw-bolder">Laura Sánchez</p>
          <p class="font-size-14 fw-bolder">Excelente atención</p>
          <p class="font-size-14 text-justify">
            Tuve una respuesta rápida a mi problema. No esperaba que fuera tan rápido. Me acompañó todo el tiempo que
            necesité ayuda y estuvo pendiente de mis dudas.
          </p>
          <img
            class="img-fluid w-100"
            ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938279/TAS/home/raiting-laura_mast8t_sr7zgl.webp"
            alt="calificacion"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="card-comments text-center mb-5">
          <img
            ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938270/TAS/home/andres_pcxd8h_zcokf7.webp"
            alt="imagen de perfil"
            width="96"
            height="96"
            loading="lazy"
          />
          <p class="text-blue-tas font-size-18 mb-0 fw-bolder">Andrés Orozco</p>
          <p class="font-size-14 fw-bolder">Es una atención rápida</p>
          <p class="font-size-14 text-justify">
            Es una atención rápida, atenta y precisa, los médicos son muy amables y dan los medicamentos indicados
          </p>
          <img
            class="img-fluid w-100"
            style="margin-bottom: -55px"
            ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938273/TAS/home/raiting-andres_lw1tyc_f9shpg.webp"
            alt="calificacion"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row d-lg-none">
    <div class="col-12">
      <p-carousel
        id="comments-carousel"
        [value]="services"
        [numVisible]="3"
        [numScroll]="1"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
        [autoplayInterval]="300000"
      >
        <ng-template let-service pTemplate="item">
          <div class="card-comments-carousel text-center mb-5">
            <img [src]="service.one_img" alt="imagen de perfil" width="96" height="96" loading="lazy" />
            <p class="text-blue-tas font-size-18 mb-0 fw-bolder">{{ service.name }}</p>
            <p class="font-size-14 fw-bolder">{{ service.experience }}</p>
            <p class="font-size-14 text-justify">{{ service.content }}</p>
            <img
              class="img-fluid w-100"
              [ngClass]="service.style"
              [src]="service.two_img"
              alt="calificacion"
              loading="lazy"
            />
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</div>
