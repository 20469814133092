/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PayPalRequestDto } from '../models/pay-pal-request-dto';

@Injectable({
  providedIn: 'root',
})
export class PayPalPaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiServicesAppPayPalPaymentConfirmPaymentPost
   */
  static readonly ApiServicesAppPayPalPaymentConfirmPaymentPostPath = '/payment/PayPal/ConfirmPayment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppPayPalPaymentConfirmPaymentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppPayPalPaymentConfirmPaymentPost$Plain$Response(params: {
    Authentication: string;
    body?: PayPalRequestDto;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PayPalPaymentService.ApiServicesAppPayPalPaymentConfirmPaymentPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppPayPalPaymentConfirmPaymentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppPayPalPaymentConfirmPaymentPost$Plain(params: {
    Authentication: string;
    body?: PayPalRequestDto;
  }): Observable<boolean> {
    return this.apiServicesAppPayPalPaymentConfirmPaymentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppPayPalPaymentConfirmPaymentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppPayPalPaymentConfirmPaymentPost$Json$Response(params: {
    Authentication: string;
    body?: PayPalRequestDto;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PayPalPaymentService.ApiServicesAppPayPalPaymentConfirmPaymentPostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: String((r as HttpResponse<any>).body) === 'true',
          }) as StrictHttpResponse<boolean>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppPayPalPaymentConfirmPaymentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppPayPalPaymentConfirmPaymentPost$Json(params: {
    Authentication: string;
    body?: PayPalRequestDto;
  }): Observable<boolean> {
    return this.apiServicesAppPayPalPaymentConfirmPaymentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }
}
