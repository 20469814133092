/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PlanDto } from '../models/plan-dto';
import { TravelInsurancePlanDto } from '../models/travel-insurance-plan-dto';
import { TravelInsurancePlanDtoPagedResultDto } from '../models/travel-insurance-plan-dto-paged-result-dto';
import { PlanSummaryDto } from '../models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TravelInsurancePlanService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  public GetPlanForSelect(TenantId: number, isB2B: boolean): Observable<PlanDto[]> {
    return this.http.get<PlanDto[]>(`${environment.serverUrl}/plan/planforSelect/${TenantId}`, {
      params: { visibility: isB2B ? 'B2B' : 'B2C' },
    });
  }

  public GetPlans(TenantId: number, isB2B: boolean, domain: string): Observable<PlanDto[]> {
    return this.http.get<PlanDto[]>(`${environment.serverUrl}/plan/plan/${TenantId}`, {
      params: { visibility: isB2B ? 'B2B' : 'B2C', domain: domain },
    });
  }
  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetAllPlansGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetAllPlansGetPath = '/plan/plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllPlansGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansGet$Plain$Response(params: {
    Authentication: string;
  }): Observable<StrictHttpResponse<Array<PlanDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllPlansGetPath,
      'get'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PlanDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllPlansGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansGet$Plain(params: {
    Authentication: string;
  }): Observable<Array<PlanDto>> {
    return this.apiServicesAppTravelInsurancePlanGetAllPlansGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanDto>>) => r.body as Array<PlanDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllPlansGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansGet$Json$Response(params: {
    TenantId: number;
  }): Observable<StrictHttpResponse<Array<PlanDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllPlansGetPath + `/${params.TenantId}`,
      'get'
    );

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PlanDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllPlansGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansGet$Json(params: { TenantId: number }): Observable<Array<PlanDto>> {
    return this.apiServicesAppTravelInsurancePlanGetAllPlansGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanDto>>) => r.body as Array<PlanDto>),
      map((r: any) => JSON.parse(r))
    );
  }
  //START
  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetAllPlansForSelectGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetAllPlansForSelectGetPath = '/plan/planforSelect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllPlansGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansForSelectGet$Json$Response(params: {
    TenantId: number;
  }): Observable<StrictHttpResponse<Array<PlanDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllPlansForSelectGetPath + `/${params.TenantId}`,
      'get'
    );

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PlanDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllPlansGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansForSelectGet$Json(params: {
    TenantId: number;
  }): Observable<Array<PlanDto>> {
    return this.apiServicesAppTravelInsurancePlanGetAllPlansForSelectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanDto>>) => r.body as Array<PlanDto>),
      map((r: any) => JSON.parse(r))
    );
  }

  //END
  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetAllPlansWithoutPricesGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetAllPlansWithoutPricesGetPath = '/plan/planwhitoutprice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllPlansGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansWithoutPricesGet$Json$Response(params: {
    TenantId: number;
  }): Observable<StrictHttpResponse<Array<PlanDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllPlansWithoutPricesGetPath +
        `/${params.TenantId}`,
      'get'
    );

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PlanDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllPlansGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansWithoutPricesGet$Json(params: {
    TenantId: number;
  }): Observable<Array<PlanDto>> {
    return this.apiServicesAppTravelInsurancePlanGetAllPlansWithoutPricesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanDto>>) => r.body as Array<PlanDto>),
      map((r: any) => JSON.parse(r))
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetAllPlansTenantsGetPath =
    '/api/services/app/TravelInsurancePlan/GetAllPlansTenants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Plain$Response(params: {
    tenantId?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<Array<PlanDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllPlansTenantsGetPath,
      'get'
    );
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PlanDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Plain(params: {
    tenantId?: number;
    Authentication: string;
  }): Observable<Array<PlanDto>> {
    return this.apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanDto>>) => r.body as Array<PlanDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Json$Response(params: {
    tenantId?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<Array<PlanDto>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllPlansTenantsGetPath,
      'get'
    );
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PlanDto>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Json(params: {
    tenantId?: number;
    Authentication: string;
  }): Observable<Array<PlanDto>> {
    return this.apiServicesAppTravelInsurancePlanGetAllPlansTenantsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanDto>>) => r.body as Array<PlanDto>)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetPlanByIdGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetPlanByIdGetPath =
    '/api/services/app/TravelInsurancePlan/GetPlanById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetPlanByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetPlanByIdGet$Plain$Response(params: {
    planId?: number;
    day?: number;
    tenantId?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetPlanByIdGetPath,
      'get'
    );
    if (params) {
      rb.query('planId', params.planId, {});
      rb.query('day', params.day, {});
      rb.query('tenantId', params.tenantId, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetPlanByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetPlanByIdGet$Plain(params: {
    planId?: number;
    day?: number;
    tenantId?: number;
    Authentication: string;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanGetPlanByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetPlanByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetPlanByIdGet$Json$Response(params: {
    planId?: number;
    day?: number;
    tenantId?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetPlanByIdGetPath,
      'get'
    );
    if (params) {
      rb.query('planId', params.planId, {});
      rb.query('day', params.day, {});
      rb.query('tenantId', params.tenantId, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetPlanByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetPlanByIdGet$Json(params: {
    planId?: number;
    day?: number;
    tenantId?: number;
    Authentication: string;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanGetPlanByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetGetPath = '/api/services/app/TravelInsurancePlan/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetGet$Plain$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetGetPath,
      'get'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetGet$Plain(params: {
    Id?: number;
    Authentication: string;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanGetGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetGet$Json$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetGetPath,
      'get'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetGet$Json(params: {
    Id?: number;
    Authentication: string;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanGetGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanGetAllGet
   */
  static readonly ApiServicesAppTravelInsurancePlanGetAllGetPath = '/api/services/app/TravelInsurancePlan/GetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllGet$Plain$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDtoPagedResultDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllGetPath,
      'get'
    );
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllGet$Plain(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<TravelInsurancePlanDtoPagedResultDto> {
    return this.apiServicesAppTravelInsurancePlanGetAllGet$Plain$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TravelInsurancePlanDtoPagedResultDto>) => r.body as TravelInsurancePlanDtoPagedResultDto
      )
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllGet$Json$Response(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDtoPagedResultDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanGetAllGetPath,
      'get'
    );
    if (params) {
      rb.query('Sorting', params.Sorting, {});
      rb.query('SkipCount', params.SkipCount, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDtoPagedResultDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanGetAllGet$Json(params: {
    Sorting?: string;
    SkipCount?: number;
    MaxResultCount?: number;
    Authentication: string;
  }): Observable<TravelInsurancePlanDtoPagedResultDto> {
    return this.apiServicesAppTravelInsurancePlanGetAllGet$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TravelInsurancePlanDtoPagedResultDto>) => r.body as TravelInsurancePlanDtoPagedResultDto
      )
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanCreatePost
   */
  static readonly ApiServicesAppTravelInsurancePlanCreatePostPath = '/api/services/app/TravelInsurancePlan/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanCreatePost$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanCreatePostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanCreatePost$Plain(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanCreatePost$Json$Response(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanCreatePostPath,
      'post'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanCreatePost$Json(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanUpdatePut
   */
  static readonly ApiServicesAppTravelInsurancePlanUpdatePutPath = '/api/services/app/TravelInsurancePlan/Update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanUpdatePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanUpdatePut$Plain$Response(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanUpdatePutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanUpdatePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanUpdatePut$Plain(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanUpdatePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanUpdatePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanUpdatePut$Json$Response(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<StrictHttpResponse<TravelInsurancePlanDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanUpdatePutPath,
      'put'
    );
    if (params) {
      rb.header('Authentication', params.Authentication, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TravelInsurancePlanDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanUpdatePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiServicesAppTravelInsurancePlanUpdatePut$Json(params: {
    Authentication: string;
    body?: TravelInsurancePlanDto;
  }): Observable<TravelInsurancePlanDto> {
    return this.apiServicesAppTravelInsurancePlanUpdatePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TravelInsurancePlanDto>) => r.body as TravelInsurancePlanDto)
    );
  }

  /**
   * Path part for operation apiServicesAppTravelInsurancePlanDeleteDelete
   */
  static readonly ApiServicesAppTravelInsurancePlanDeleteDeletePath = '/api/services/app/TravelInsurancePlan/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServicesAppTravelInsurancePlanDeleteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanDeleteDelete$Response(params: {
    Id?: number;
    Authentication: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TravelInsurancePlanService.ApiServicesAppTravelInsurancePlanDeleteDeletePath,
      'delete'
    );
    if (params) {
      rb.query('Id', params.Id, {});
      rb.header('Authentication', params.Authentication, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiServicesAppTravelInsurancePlanDeleteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServicesAppTravelInsurancePlanDeleteDelete(params: { Id?: number; Authentication: string }): Observable<void> {
    return this.apiServicesAppTravelInsurancePlanDeleteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  static readonly ApiGetAllPlanSummary = '/form/Forms/GetAllPlanSummaries';

  getAllPlanSummary(): Observable<PlanSummaryDto[]> {
    return this.http
      .get(`${this.rootUrl + TravelInsurancePlanService.ApiGetAllPlanSummary}`)
      .pipe(map((res: any) => res.Result));
  }
}
