import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@shared';
import { normalize } from '@core/utils';
import { DocumentsService, QuotationService, TravelInsurancePlanService } from '@core/api/services';
import { AlertService, ParameterByDomainService, StorageService } from '@core/services';
import { QuoteModel } from '@shared/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  GeolocationDto,
  PlanDto,
  QuotationRequestDto,
  TravelInsuranceQuoteResponseDto,
  TravelInsuranceRequote,
  VisaDto,
} from '@core/api/models';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { DEFAULT_LOCATION } from './default-location';
import { PhoneValidatorService } from './services/phone-validator/phone-validator.service';
import { GoogleAnaliticsService } from '@app/@core/services/analytics/google-analitics.service';
import { addDays, format } from 'date-fns';
import { InfoFooter } from '@app/interfaces/domain.interface';

@UntilDestroy()
@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit, OnDestroy {
  @Input() public isB2B: boolean;
  @Input() public quoteCode!: string;
  @Input() public travelInsurance!: TravelInsuranceQuoteResponseDto | undefined;
  @Output() private reQuote: EventEmitter<void>;
  @ViewChild(DatePickerComponent) public dpComponent!: DatePickerComponent;

  discount = false;
  changeDiscount(): void {
    this.discount = !this.discount;
  }
  public linkFenix: string;
  get changes2(): number {
    return this.quoteForm.value.origin &&
      this.quoteForm.value.destiny &&
      this.quoteForm.value.arrivalDate &&
      this.quoteForm.value.departureDate
      ? 1
      : 0;
  }
  changes: number = 1;

  public source!: string;
  public location: GeolocationDto;
  public quoteForm: FormGroup;
  public quoteModel: QuoteModel;
  public indicatives: VisaDto[] = [];
  public infoFooterBottom: InfoFooter | undefined;

  public loading: boolean;
  public activeCollapseDiscountCode: string;
  public showIntel: boolean = true;
  public formCounter: number;
  public formParticular: boolean;
  public quotationType: string = '';
  public showDiscount: boolean = false;
  private allowedSubdomains = [
    'co',
    'mx',
    'cl',
    'br',
    'pe',
    'bz',
    'gt',
    'hn',
    'ar',
    'bo',
    've',
    'cr',
    'do',
    'ni',
    'pa',
    'us',
    'ec',
  ];
  // B2B
  public namePlan?: null | string;
  public planPrice?: number;
  public AgeLimit?: string;
  public planCotizado: boolean = false;
  public CurrencyDescriptionPlan?: string;
  public CoveragePlan?: number;

  public plans$: Observable<PlanDto[]>;
  public hashCode: string;
  get plan(): PlanDto | null {
    return (
      this.travelInsurance?.Plans?.find((plans) => plans.Id === this.travelInsurance?.PlanSelectedId) ??
      this.travelInsurance?.Plans?.slice(0, 1).shift() ??
      null
    );
  }

  public $onInit: EventEmitter<{ inCheckout: boolean }>;

  get origin(): VisaDto | null {
    return this.location?.Visas?.find((visa) => visa.Id == this.quoteModel.getControl('origin')?.value) ?? null;
  }

  get destiny(): VisaDto | null {
    return this.location?.Visas?.find((visa) => visa.Id == this.quoteModel.getControl('destiny')?.value) ?? null;
  }

  get intl(): VisaDto | null {
    return this.location?.Visas?.find((visa) => visa.AlphaCode == this.quoteModel.getControl('intl')?.value) ?? null;
  }

  constructor(
    private readonly _parameterDomain: ParameterByDomainService,
    private readonly _quote: QuotationService,
    private readonly _storage: StorageService,
    private readonly _alert: AlertService,
    private router: Router,
    private readonly _plan: TravelInsurancePlanService,
    private route: ActivatedRoute,
    private _phoneValidator: PhoneValidatorService,
    private _analytics: GoogleAnaliticsService,
    private _documents: DocumentsService,
    private ref: ChangeDetectorRef
  ) {
    this.isB2B = false;
    this.reQuote = new EventEmitter();
    this.loading = false;
    this.hashCode = '';
    this.location = DEFAULT_LOCATION;
    this.quoteForm = new FormGroup({});
    this.quoteModel = new QuoteModel();
    this.formCounter = 0;
    this.formParticular = false;
    this.activeCollapseDiscountCode = '';
    this.$onInit = new EventEmitter();
    this.plans$ = of([]);
    this.linkFenix = environment.fenixurl;
  }

  ngOnInit(): void {
    this.init();
    this.source = window.location.href;
    this._parameterDomain
      .getGeolocation()
      .pipe(untilDestroyed(this))
      .subscribe((location) => {
        if (location.Visas) this.indicativos(location.Visas);
        this.getInfoFooter();
        this.location = location;
        //this.checkSubDomain(location.AlphaCode?.toLowerCase()!);
        if (this.travelInsurance?.TravelInsuranceRequest) {
          this.setFormByQuote();
        } else {
          this.quoteModel.getControl('intl')?.setValue(this.location.AlphaCode);
          if (this.quoteModel.getControl('origin')?.value == 1) this.quoteModel.getControl('destiny')?.setValue(3);
          else this.quoteModel.getControl('destiny')?.setValue(location.Visas?.slice(0, 1).shift()?.Id);
          this.setOrigin();
          this.$onInit.emit({ inCheckout: false });
          setTimeout(() => {
            this.setInfoRequote();
          }, 3000);
        }
      });

    //this.setOrigin();

    if (this.isB2B) {
      // this.loadingPlans = true;
      this.quoteModel.getControl('phone')?.clearValidators();
      this.setPlans();
    } else {
      this.phoneValidator();
    }
  }

  ngOnDestroy(): void {
    this._phoneValidator.disconnect();
  }

  indicativos(visas: VisaDto[]) {
    let clean: { [key: string]: boolean } = {};
    this.indicatives =
      visas
        ?.filter((intlCode) => (clean[intlCode.AlphaCode!] ? false : (clean[intlCode.AlphaCode!] = true)))
        .filter((intlCode) => intlCode.WP !== '0')
        .sort((a, b) => parseFloat(a.WP!) - parseFloat(b.WP!)) ?? [];
  }

  getInfoFooter() {
    this._parameterDomain
      .getInfoFooterByTopLevelDomain(this._storage.getTopLevelDomain())
      .subscribe((infoFooter) => (this.infoFooterBottom = infoFooter));
  }

  /** Validar si no esta en el subdominio de su geolocalizacion */
  private checkSubDomain(countryCode: string) {
    var topLevelDomain = this._storage.getItem('topLevelDomain');
    if (this.allowedSubdomains.includes(countryCode)) {
      if (topLevelDomain != countryCode && !this._storage.getItem('PopUpRedirect')) {
        this._storage.setItem('PopUpRedirect', true);
        this._alert.swal
          .fire({
            html: 'Estas en <b>Traveler Assistance Services ' + this.infoFooterBottom?.name + '</b>',
            iconHtml: `<img src="${this.infoFooterBottom?.flagDomain}" width="90">`,
            showDenyButton: true,
            confirmButtonText: `Cotizar lo mismo en ${this.location.VisaName}`,
            denyButtonText: `Quedarme en ${this.infoFooterBottom?.name}`,
            width: '385px',
            showCloseButton: true,
            customClass: {
              confirmButton: 'btn-confirm',
              denyButton: 'btn-deny',
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.href = `https://${this.location.AlphaCode}.traveler-assistance.com`;
            }
          });
      }
    }
  }

  /**Validacion de numeros de telefono con Socket */
  private phoneValidator(): void {
    this._phoneValidator.connect();
    this.quoteForm
      .get('phone')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => this.emitPhoneValidatorOrSetError(value));

    this.quoteForm
      .get('intl')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) =>
        this.emitPhoneValidatorOrSetError(this.quoteForm.get('phone')?.value, this.intl?.AlphaCode!)
      );

    this._phoneValidator
      .fromEvent('is_valid')
      .pipe(
        filter((valid: any) => valid),
        untilDestroyed(this)
      )
      .subscribe(() => this.quoteModel.getControl('phone')?.setErrors(null));
  }

  private emitPhoneValidatorOrSetError(phoneNumber: string, code?: string): void {
    this.quoteForm.get('phone')?.setErrors({ notValid: true });
    if (phoneNumber?.length > 6) {
      const countryCode = code ?? this.intl?.AlphaCode;
      this._phoneValidator.emit('is_valid', {
        phoneNumber,
        countryCode: countryCode == 'USA' ? 'US' : countryCode,
      });
    }
    if (this.showIntel == false) this.quoteModel.getControl('phone')?.setErrors(null);
  }

  private init(model?: QuoteModel): void {
    // Creación del formulario
    this.quoteModel = model ?? new QuoteModel();
    this.quoteForm = this.quoteModel.createForm(); // Solo es referencia del Form en QuoteModel
    if (!this.isB2B) {
      this._parameterDomain.parameter$.subscribe((data: any) => {
        this.quoteForm.controls.discountCode.setValue(
          this.route.snapshot.queryParams.discountcode ? this.route.snapshot.queryParams.discountcode : data?.Code
        );
      });
      this.quoteForm.controls.userId.setValue(
        this.route.snapshot.queryParams.user
          ? this.route.snapshot.queryParams.user
          : this.travelInsurance?.UserId ?? environment.userB2C
      );
    }
  }

  validateDates(arrivalDate: Date, departureDate: Date) {
    // Validar si la primera fecha es mayor que la fecha actual
    if (departureDate <= new Date()) {
      departureDate = new Date(); // Asignar fecha actual
    }

    // Calcular la diferencia en milisegundos entre las dos fechas
    const diffTiempo = arrivalDate.getTime() - departureDate.getTime();
    const diffDias = diffTiempo / (1000 * 3600 * 24); // Convertir diferencia a días

    // Verificar si la diferencia es menor que 3 días
    if (diffDias < 3) {
      // Agregar 3 días a la fecha inicial
      arrivalDate = new Date(departureDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    }
    // Si la diferencia es de 3 días o más, no hace falta hacer nada, la fecha final ya está correcta
  }

  setInfoRequote() {
    if (this._storage.getItem('HashCode') && !this.quoteCode && !this.isB2B) {
      this.travelInsurance = this._storage.getItemJson<TravelInsuranceQuoteResponseDto>('OldQuote');
      const values = this._storage.getItemJson<TravelInsuranceRequote>('OldQuote');
      this.quoteModel.getControl('origin')?.setValue(this.travelInsurance!.Origin?.Id);
      this.quoteModel.getControl('destiny')?.setValue(this.travelInsurance!.Destiny?.Id);

      var departureDate = new Date(this.travelInsurance!.DepartureDate!);
      var arrivalDate = new Date(this.travelInsurance!.ArrivalDate!);
      var actualDate = new Date();
      actualDate.setHours(0, 0, 0, 0);

      if (departureDate <= actualDate) {
        departureDate = actualDate;
      }

      const diffTiempo = arrivalDate.getTime() - departureDate.getTime();
      const diffDias = diffTiempo / (1000 * 3600 * 24);

      if (diffDias < 2) {
        arrivalDate = new Date(departureDate.getTime() + 2 * 24 * 60 * 60 * 1000);
      }

      this.quoteModel.getControl('departureDate')?.setValue(departureDate.toISOString().slice(0, 10) + 'T00:00:00');
      this.quoteModel.getControl('arrivalDate')?.setValue(arrivalDate.toISOString().slice(0, 10) + 'T00:00:00');

      this.quoteModel.getControl('email')?.setValue(this.travelInsurance!.Email);
      this.quoteModel.getControl('discountCode')?.setValue(this.travelInsurance!.DiscountCode);
      if (this.location.WP && this.travelInsurance!.Phone!.length > 10) {
        var phoneIntl = this.travelInsurance!.Phone!.slice(0, 4);
        phoneIntl = phoneIntl.replace(this.location.WP, '');
        var phoneVal = this.travelInsurance!.Phone!.slice(4, this.travelInsurance!.Phone!.length);
        this.quoteModel.getControl('phone')?.setValue(phoneIntl + phoneVal);
      } else {
        this.quoteModel.getControl('phone')?.setValue(this.travelInsurance!.Phone);
      }
      this.quoteModel.removeAge(this.quoteModel.agesControl.length - 1);
      this.travelInsurance?.Customers?.forEach((customer, index) => {
        this.increase();
        this.quoteModel.agesControl.get(`${index}`)?.setValue(customer.Age);
      });
      this.ref.markForCheck();
      setTimeout(() => this.$onInit.emit({ inCheckout: true }));
    }
  }

  async modalRequotation() {
    try {
      if (this._storage.getItem('HashCode') && !this.quoteCode) {
        this.quote();
      } else {
        this.quote();
      }
    } catch (error) {
      console.error('Error mostrando SweetAlert:', error);
    }
  }

  quote() {
    console.log(this.quoteModel.agesControl.touched + 'ages contrrol');
    /*if (this.isB2B) {
      this.quoteForm.controls.lastName.disable();
      this.quoteForm.controls.firstName.disable();
    }*/

    console.log(this.quoteForm);
    if (this.quoteForm.invalid) {
      this.errorMessageInvalidForm();
      return;
    }
    this.loading = true;
    const form = this.quoteModel.onSubmit();
    const urlParams = new URLSearchParams(this.source);
    if (
      this.travelInsurance &&
      form.email === this.travelInsurance.Email &&
      JSON.stringify(form.ages) === JSON.stringify(this.travelInsurance.Customers?.map((c) => c.Age))
    ) {
      this.UpdateQuote(form);
    } else {
      const intl = this.indicatives.find((visas) => visas.AlphaCode == form.intl)?.WP;
      const body: QuotationRequestDto = {
        ages: form.ages,
        entryChannel: 'Online',
        email: form.email,
        arrivalDate: form.arrivalDate,
        departureDate: form.departureDate,
        destinyVisaId: form.destiny,
        originVisaId: form.origin,
        phone: (intl ?? '') + form.phone,
        discountCode: form.discountCode?.toLocaleUpperCase(),
        source: `https://${this._storage.getItem('domainName')}`,
        userid: form.userId,
        TenantId: environment.tenantId,
        //FirstName: form.firstName,
        //LastName: form.lastName,
        Metadata: JSON.stringify({
          UTM: urlParams.has('utm_medium')
            ? this.source
            : this.source + '?utm_source=google&utm_medium=organic&utm_campaign=search',
        }),
        cityName: this.location.City,
        visaAlphaCode: this.location.AlphaCode,
      };
      this.quotationType = this.isB2B ? 'QuotationB2B' : 'Quotation';
      if (!this.isB2B) {
        this._quote
          .apiServicesAppQuotationQuotePost$Json({ Authentication: '', body })
          .pipe(
            finalize(() => (this.loading = false)),
            map((response: any) => response.result)
          )
          .subscribe(
            (response) => {
              const res = JSON.parse(response as any);
              let queryParams: Params = {};

              if (this._storage.getItem('planSelectedIdFromPlans')) {
                queryParams.relateds = btoa(this._storage.getItem('planSelectedIdFromPlans') ?? '');
              }

              this.reQuote.emit(res['HashCode']);
              this.router.navigate(['/cotizar/detalle', res['HashCode']], {
                queryParams,
              });

              if (!this.travelInsurance?.Status) {
                this._storage.setItem('firstPlanSelection', true);
              }

              /**Evento para GoogleAnalytics*/
              this._analytics.addEvent('cotizar');
            },
            (e) => {
              // TODO: Message Error
              console.log(e);
              if (e.error.message == 'Cotización Invalida') {
                this._alert.alertFire(
                  'Planes no disponibles',
                  'Por el momento, no tenemos planes nacionales disponibles en este país',
                  'warning'
                );
              }
            }
          );
      } else {
        const planId = parseInt(form.planId.toString());
        body.userid = environment.userB2B;
        body.phone = '';
        this._quote
          .apiQuotationB2B(body)
          .pipe(
            finalize(() => (this.loading = false)),
            map((response: any) => response.result)
          )
          .subscribe(async (res) => {
            const response = JSON.parse(res as any);
            if (response.HashCode) {
              await this.setDataB2B(planId, response);
            }
          });
      }
    }
  }

  private async setDataB2B(planId: number | null | undefined, response: { HashCode: string }) {
    this.hashCode = response.HashCode;
    console.log(this.hashCode);
    this.travelInsurance = await this._quote
      .apiGetTravelInsuranceRequestQuoteB2B({
        HashCode: response.HashCode,
        TenantId: environment.tenantId,
      })
      .toPromise();

    const currentPlan = this.travelInsurance?.Plans?.find((plan) => plan.Id === planId);

    if (currentPlan) {
      this.travelInsurance!.PlanSelectedId = planId;
      this.namePlan = currentPlan.Name;
      this.planPrice = currentPlan.Price;
      this.AgeLimit = currentPlan.AgeLimit?.replace('a&ntilde;os', 'años') ?? ' ';
      this.CurrencyDescriptionPlan = currentPlan.CurrencyDescription ?? '';
      this.CoveragePlan = currentPlan.Coverage;
    }

    this.completeInfoB2B(
      this.travelInsurance!.PlanSelectedId ?? this.travelInsurance!.Plans?.slice(0, 1).shift()?.Id ?? 1
    );

    this.plans$ = of(this.travelInsurance!.Plans ?? []);

    if (this.plan?.Id == this.quoteForm.value.planId) {
      this.planCotizado = true;
    } else {
      this.planCotizado = false;
    }
  }

  private UpdateQuote(form: QuoteModel) {
    const body = {
      PlanId: this.travelInsurance!.PlanSelectedId,
      QuoteCode: this.isB2B ? this.hashCode : this.quoteCode ? this.quoteCode : this._storage.getItem('HashCode'),
      OriginId: form.origin,
      DestinyId: form.destiny,
      ArrivalDate: form.arrivalDate,
      DepartureDate: form.departureDate,
      Phone: form.phone,
      DiscountCode: form.discountCode,
      UserId: this.isB2B ? environment.userB2B : environment.userB2C,
      TenantId: environment.tenantId,
      Metadata: this.setMetadata(this.travelInsurance?.Metadata),
    };
    console.log(body);
    this._quote
      .UpdateQuotation(body)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: async () => {
          if (!this.isB2B) {
            this.reQuote.emit();
            if (!this.quoteCode && this._storage.getItem('HashCode')) {
              this.router.navigate(['/cotizar/detalle', this._storage.getItem('HashCode')]);
            }
          } else if (this.isB2B) {
            await this.setDataB2B(parseInt(form.planId.toString()), { HashCode: this.hashCode });
          }
          this.quoteForm.markAsPristine();
        },
        error: (err) => {
          console.log(err);
          if (err.error.message == 'Cotización Invalida') {
            this._alert.alertFire(
              'Planes no disponibles',
              'Por el momento, no tenemos planes nacionales disponibles en este país',
              'warning'
            );
          }
        },
      });
  }

  increase() {
    if (this.changes === 0) {
      this.quoteModel.removeAge(this.quoteModel.agesControl.length - 1);
      this.warm();
    }
    this.changes = 1;
    if (this.quoteModel.agesControl.length > 9) return;
    this.quoteModel.addAge();
  }

  decrease() {
    this.changes - 1;
    if (this.quoteModel.agesControl.length <= 1) return;
    this.quoteModel.removeAge(this.quoteModel.agesControl.length - 1);
    this.quoteModel.agesControl.markAsDirty();
  }

  closeForm() {
    this.formParticular = false;
    this.formCounter = 0;
  }

  sendMailB2b() {
    this._documents.apiServicesAppDocumentsSendEmailQuotePost$Json(this.hashCode).subscribe();
    this._alert.swal.fire({
      title: 'Enviado',
      text: `Se envió la cotización al correo ${this.travelInsurance!.Email}`,
      icon: 'success',
      confirmButtonColor: '#004686',
    });
  }

  public newQuoteB2B() {
    this.init();
    this.$onInit.emit({ inCheckout: false });
    this.setPlans();
    this.changes = 0;
    this.travelInsurance = {} as any;
    this.quoteModel.getControl('phone')?.clearValidators();
    this.quoteModel.getControl('intl')?.setValue(this.location.WP);
  }

  private setPlans() {
    this.plans$ = this._plan
      .GetPlanForSelect(environment.tenantId, this.isB2B)
      .pipe(tap((plans) => this.quoteModel.getControl('planId')?.setValue(plans[0].Id)));
  }

  private completeInfoB2B(planId: number) {
    this.travelInsurance!.PlanSelectedId = planId;
    const currentPlan = this.travelInsurance?.Plans?.find((plan) => plan.Id === planId);
    const perksAdditionalRate = this.travelInsurance?.PerksAdditionalRate ?? 1;

    this._quote
      .apiSetCompleteInfoB2B({
        QuoteCode: this.hashCode,
        PlanId: currentPlan?.Id,
        Price: (currentPlan?.Price ?? 1) + perksAdditionalRate,
        PriceWithoutModifiers: currentPlan?.PriceWithoutModifiers,
        PerksPrice: 0,
        ExchangeRate: this.travelInsurance?.ExchangeRate,
      })
      .toPromise();
  }

  /** Paises */
  private setOrigin() {
    if (this.location.VisaName)
      this.quoteModel.getControl('origin')?.setValue(this.getIdVisaByName(this.location.VisaName));
    else
      this._parameterDomain
        .getDomainByTopLevelDomain(this._storage.getTopLevelDomain())
        .pipe(untilDestroyed(this))
        .subscribe((domain) =>
          this.quoteModel.getControl('origin')?.setValue(this.getIdVisaByName(domain?.name ?? ''))
        );
  }

  private getIdVisaByName(name: string): number {
    return (
      this.location.Visas?.find((visa) =>
        normalize(visa.Name ?? '')
          .toLowerCase()
          .includes(normalize(name).toLowerCase())
      )?.Id ?? 1
    );
  }
  /** Fin Paises */

  private setFormByQuote(): void {
    this.datosModificados = true;
    const mainCustomer = this.travelInsurance!.Customers?.find((c) => c.IsMainCustomer);
    this.quoteModel.getControl('origin')?.setValue(this.travelInsurance!.Origin?.Id);
    this.quoteModel.getControl('destiny')?.setValue(this.travelInsurance!.Destiny?.Id);
    this.quoteModel.getControl('email')?.setValue(this.travelInsurance!.Email);
    this.quoteModel.getControl('discountCode')?.setValue(this.travelInsurance!.DiscountCode);
    this.quoteModel.getControl('phone')?.setValue(this.travelInsurance!.Phone);
    this.quoteModel.getControl('userId')?.setValue(this.travelInsurance!.UserId);
    this.quoteModel.getControl('firstName')?.setValue(mainCustomer?.Name);
    this.quoteModel.getControl('lastName')?.setValue(mainCustomer?.LastName);
    untilDestroyed((this.showIntel = false));
    this.quoteModel.getControl('phone')?.setErrors(null);

    // Show Input Discount Code
    if ((this.travelInsurance!.DiscountCode?.length ?? 0) > 3) {
      this.activeCollapseDiscountCode = 'toggle-discountCode';
    }

    // Set Travel Dates
    this.quoteModel.getControl('arrivalDate')?.setValue(this.travelInsurance!.ArrivalDate);
    this.quoteModel.getControl('departureDate')?.setValue(this.travelInsurance!.DepartureDate);

    setTimeout(() => this.$onInit.emit({ inCheckout: true }));

    // Set Ages
    this.quoteModel.removeAge(this.quoteModel.agesControl.length - 1);
    this.travelInsurance?.Customers?.forEach((customer, index) => {
      this.increase();
      this.quoteModel.agesControl.get(`${index}`)?.setValue(customer.Age);
    });
  }

  private errorMessageInvalidForm(): void {
    if (this.quoteModel.getControl('departureDate')?.invalid || this.quoteModel.getControl('arrivalDate')?.invalid) {
      this.dpComponent.datepicker.open();
    }

    this.quoteForm.markAllAsTouched();
  }
  datosModificados: boolean = false;

  private warm(): void {
    const body: QuotationRequestDto = {
      ages: [70],
      entryChannel: 'Online',
      email: 'test@warmup.com',
      arrivalDate: format(addDays(new Date(), 3), 'yyyy-MM-dd'),
      departureDate: format(new Date(), 'yyyy-MM-dd'),
      destinyVisaId: 1,
      originVisaId: 14,
      phone: '0000000000',
      source: 'https://traveler-assistance.com',
      userid: 3,
      TenantId: 3,
      FirstName: '',
      LastName: '',
      discountCode: '',
    };
    this._quote.TemporalQuote(body).subscribe();
  }

  private setMetadata(data: string | undefined): string {
    if (data) {
      // Parseamos el JSON Metadata a un objeto
      let metadataObj = JSON.parse(data);

      // Verificamos si `UTM` existe y no es null
      if (metadataObj.UTM) {
        // Convertimos la UTM en una URL para analizar sus parámetros
        let url = new URL(metadataObj.UTM);

        // Verificamos si `utm_medium` existe en los parámetros de la URL
        if (!url.searchParams.has('utm_medium')) {
          metadataObj.UTM = this.source + '?utm_source=google&utm_medium=organic&utm_campaign=search';
        } else {
          metadataObj.UTM = url.toString();
        }
      } else {
        // Si `UTM` es null, lo generamos con los parámetros deseados
        metadataObj.UTM = this.source + '?utm_source=google&utm_medium=organic&utm_campaign=search';
      }
      data = JSON.stringify(metadataObj);
    } else {
      data = JSON.stringify({
        UTM: this.source + '?utm_source=google&utm_medium=organic&utm_campaign=search',
      });
    }

    return data;
  }
}
