<div class="container py-5">
  <h2 class="title-blue text-center font-size-32">Preguntas Frecuentes</h2>
  <div class="row pt-5">
    <div class="col-md-12 faq-cont">
      <ngb-accordion [closeOthers]="true" [animation]="'slide'" class="text-black grid-card" id="faqAccordion">
        <ngb-panel id="panelOne" cardClass="panel-faq mb-3">
          <ng-template ngbPanelTitle>
            <h3 class="text-blue-tas fw-bold lh-1 mb-0 font-size-17">1. ¿Cómo usar tu asistencia de viaje?</h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="font-size-md-13 font-size-lg-13 font-size-xl-15 font-size-13 mb-0">
              Primero comunícate con la central de asistencia de Traveler Assistance Services: a través de una llamada,
              chat online 24/7, WhatsApp, correo o vía Skype para solicitar asistencia. Luego, para agilizar el proceso
              de asistencia médica, sigue atentamente las instrucciones del operador. Recuerda que sí estás presentando
              una urgencia que comprometa tus signos vitales, debes acudir al centro médico más cercano y contactar a la
              central de asistencia durante las primeras 24 horas después de ocurrido el evento.
            </p>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panelTwo" cardClass="panel-faq mb-3">
          <ng-template ngbPanelTitle>
            <h3 class="text-blue-tas fw-bold lh-1 mb-0 font-size-17">
              2. ¿Cuándo debo comprar la asistencia de viaje?
            </h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="font-size-md-13 font-size-lg-13 font-size-xl-15 font-size-13 mb-0">
              La compra la puedes realizar cuando lo desees. No obstante, con el fin de evitar cualquier imprevisto,
              recomendamos que realices la compra por lo menos 48 horas antes del inicio de tu viaje. De esta forma,
              tendrás el tiempo necesario para solicitar cualquier cambio o modificación en caso de que sea necesario.
            </p>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panelThree" cardClass="panel-faq mb-3">
          <ng-template ngbPanelTitle>
            <h3 class="text-blue-tas fw-bold lh-1 mb-0 font-size-17">
              3. ¿La asistencia de viaje cubre enfermedades preexistentes?
            </h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="font-size-md-13 font-size-lg-13 font-size-xl-15 font-size-13 mb-0">
              Nuestro compromiso es cuidar de ti y tus experiencias, por eso el certificado de asistencia de viaje cubre
              todo lo que tenga que ver con siniestros que imposibiliten el transcurso normal del viaje. Aunque según el
              plan que elijas, podrás tener acceso a beneficios por enfermedades preexistentes como: urgencias vitales,
              consultas ambulatorias y medicamentos para estabilizar síntomas.
            </p>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panelFour" cardClass="panel-faq mb-3">
          <ng-template ngbPanelTitle>
            <h3 class="text-blue-tas fw-bold lh-1 mb-0 font-size-17">
              4. ¿Qué hacer en caso de requerir una asistencia en viaje?
            </h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="font-size-md-13 font-size-lg-13 font-size-xl-15 font-size-13 mb-0">
              Primero debes revisar en tu certificado si el beneficio que requieres se encuentra en los ítems de
              cobertura. Luego, es muy importante que reportes a la central de asistencia lo ocurrido de forma inmediata
              y antes de abandonar el aeropuerto, ya que gran parte de los beneficios requieren el reporte inmediato con
              el fin de adelantar seguimiento o proceso con las aerolíneas o entes involucrados. De esta forma, podremos
              brindarte una respuesta acorde a las condiciones del servicio.
            </p>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panelFive" cardClass="panel-faq mb-3 h-item-card">
          <ng-template ngbPanelTitle>
            <h3 class="text-blue-tas fw-bold lh-1 mb-0 font-size-17">
              5. ¿La tarjeta de asistencia tiene copago o pagos extras?
            </h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="font-size-md-13 font-size-lg-13 font-size-xl-15 font-size-13 mb-0">
              Verifica en tu certificado de asistencia el tipo de plan que adquiriste. Si el ítem “copago” aparece en
              ceros, no tienes que pagar montos adicionales.
            </p>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panelSix" cardClass="panel-faq mb-3 h-item-card">
          <ng-template ngbPanelTitle>
            <h3 class="text-blue-tas fw-bold lh-1 mb-0 font-size-17">
              6. ¿Qué tipos de situaciones no médicas están cubiertas por la asistencia de viaje?
            </h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="font-size-md-13 font-size-lg-13 font-size-xl-15 font-size-13 mb-0">
              Están cubiertas situaciones como la pérdida de equipaje, pérdida de elementos electrónicos, retraso de
              vuelo, cancelación de viaje y demás beneficios que garantizan tu tranquilidad y seguridad.
            </p>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
<div class="container-fluid bg-quote-faq">
  <button type="button" (click)="openDialog()" class="btn btn-danger texte-center btn-quote-faq">COTIZAR GRATIS</button>
</div>
<div class="container mt-5">
  <h2 class="title-blue text-center font-size-32">Juntos Somos Más</h2>
  <p class="text-lg-center text-justify mt-4">
    <span class="text-blue-tas fw-bolder">¡Lo que es importante para ti, es importante para nosotros!</span>
    <br class="d-none d-lg-block" />
    Seguimos trabajando activamente en conjunto con nuestros aliados para brindarte las mejores soluciones médicas y
    legales cuando viajas.
  </p>
  <div class="row d-none d-md-flex mb-5 mt-5">
    <div class="col-4 center-img mb-4">
      <img
        ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1698815081/TAS/home/Logo_VTC_o99hqh.png"
        alt="logo vtc"
        width="199.96px"
        height="43.82px"
      />
    </div>
    <div class="col-4 center-img mb-4">
      <img
        ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1698815080/TAS/home/logo-DKV-tas_uvp0a7.png"
        alt="logo dkv"
        width="121.14px"
        height="46.098px"
      />
    </div>
    <div class="col-4 center-img mb-4">
      <img
        ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1698815070/TAS/home/Logo-SPV_egnbgt.png"
        alt="logo spv"
        width="212.13px"
        height="42.468px"
      />
    </div>
  </div>
  <div class="row mt-5 d-md-none" id="faqCarousel">
    <div class="col-12">
      <ngb-carousel class="h-carousel">
        <ng-template ngbSlide>
          <div class="center-img">
            <img
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1698815081/TAS/home/Logo_VTC_o99hqh.png"
              alt="logo vtc"
              width="199.96px"
              height="43.82px"
            />
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="center-img">
            <img
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1698815080/TAS/home/logo-DKV-tas_uvp0a7.png"
              alt="logo dkv"
              width="121.14px"
              height="46.098px"
            />
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="center-img">
            <img
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1698815070/TAS/home/Logo-SPV_egnbgt.png"
              alt="logo spv"
              width="212.13px"
              height="42.468px"
            />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>
