<nav
  class="navbar navbar-expand-lg menu-header sticky-top"
  id="header"
  *ngIf="showHeader"
  [ngStyle]="currentRoute === '/cotizador-de-proveedores' ? { 'background-color': '#004686' } : {}"
>
  <div class="container-fluid" *ngIf="currentRoute !== '/cotizador-de-proveedores'">
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
      <li class="fa fa-bars"></li>
    </button>
    <a
      class="btn-whatsapp mobile"
      rel="noopener noreferrer"
      (click)="contactEvent()"
      aria-label="redirección WhatsApp de ventas"
      target="_blank"
      [href]="linkWhats"
    >
      <img
        src="https://res.cloudinary.com/pervolare-org/image/upload/v1705335919/TAS/side-bar/wsp_mu5y4j.svg"
        alt="icon whatsapp"
      />
    </a>
    <span class="navbar-brand col-12 col-lg-2" routerLink="/"
      ><img
        class="pointer-event"
        id="brand"
        src="https://res.cloudinary.com/pervolare-org/image/upload/v1698425615/TAS/home/geop3aigtgwhisftzl7r.svg"
        alt="logo traveler assistance services"
        width="min-content"
        height="min-content"
    /></span>
    <div class="collapse navbar-collapse justify-content-end align-items-center col-10 row menu-options">
      <ul class="navbar-nav d-flex col-12">
        <li class="flex-fill nav-item">
          <a
            class="btn-whatsapp"
            rel="noopener noreferrer"
            (click)="contactEvent()"
            aria-label="redirección WhatsApp de ventas"
            target="_blank"
            [href]="linkWhats"
          >
            <img
              src="https://res.cloudinary.com/pervolare-org/image/upload/v1705335919/TAS/side-bar/wsp_mu5y4j.svg"
              alt="icon whatsapp"
            />
          </a>
        </li>
        <li class="flex-fill nav-item">
          <div ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="dropdown-toggle btn btn-outline-primary"
              id="dropdownBasic1"
              routerLink="/planes"
            >
              Planes de asistencia
            </button>
          </div>
        </li>
        <li class="flex-fill nav-item">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle>
              Conócenos
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <button routerLink="/nosotros/por-que" ngbDropdownItem>Nosotros</button>
              <button routerLink="/contacto" ngbDropdownItem>Contacto</button>
              <a href="{{ urlBlog }}" target="_blank" class="text-decoration-none"
                ><button ngbDropdownItem>Blog</button></a
              >
            </div>
          </div>
        </li>
        <li class="flex-fill nav-item">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic3" ngbDropdownToggle>Ayuda</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
              <button routerLink="/consulta" ngbDropdownItem>Consulta tu certificado</button>
              <button routerLink="/preguntas-frecuentes" ngbDropdownItem>Preguntas Frecuentes</button>
            </div>
          </div>
        </li>
        <li class="flex-fill nav-item">
          <button class="btn-blue-header button-emp" routerLink="/cotizador-de-proveedores">Portal agencias</button>
        </li>
        <li class="flex-fill nav-item">
          <button class="btn-red-header">
            <a [href]="linkEmergency" target="_blank" class="text-decoration-none">Reportar emergencia</a>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="container-fluid" *ngIf="currentRoute === '/cotizador-de-proveedores'">
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
      <li class="fa fa-bars"></li>
    </button>
    <a
      class="btn-whatsapp mobile"
      rel="noopener noreferrer"
      (click)="contactEvent()"
      aria-label="redirección WhatsApp de ventas"
      target="_blank"
      [href]="linkWhatsAppB2B"
    >
      <img
        src="https://res.cloudinary.com/pervolare-org/image/upload/v1705335919/TAS/side-bar/wsp_mu5y4j.svg"
        alt="icon whatsapp"
      />
    </a>
    <div class="container d-flex justify-content-between">
      <span
        *ngIf="currentRoute === '/cotizador-de-proveedores'"
        class="navbar-brand col-12 col-lg-2"
        (click)="scrollToTop()"
        ><img
          class="pointer-event"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1698435900/TAS/home/logo-tas-white_et6rf0.svg"
          alt="logo traveler assistance services"
          width="min-content"
          height="min-content"
      /></span>
      <span *ngIf="currentRoute !== '/cotizador-de-proveedores'" class="navbar-brand col-12 col-lg-2" routerLink="/"
        ><img
          class="pointer-event"
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1698435900/TAS/home/logo-tas-white_et6rf0.svg"
          alt="logo traveler assistance services"
          width="min-content"
          height="min-content"
      /></span>
      <div class="d-none d-lg-flex align-items-center col-lg-10">
        <ul class="navbar-nav d-flex col-12 justify-content-end">
          <li class="nav-item">
            <div ngbDropdown class="d-inline-block me-3 content-menu">
              <button
                type="button"
                class="btn btn-outline-primary text-white item-menu"
                id="dropdownBasic2"
                ngbDropdownToggle
              >
                Conócenos
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2" style="padding: 0px 10px" class="item-nav">
                <button class="item-nav-border" routerLink="/nosotros/por-que" ngbDropdownItem>Nosotros</button>
                <button style="padding: 10px 0px 8px 0px" routerLink="/contacto" ngbDropdownItem>Contacto</button>
              </div>
            </div>
          </li>
          <li class="nav-item me-2">
            <a [href]="linkFenix" target="_blank" class="text-decoration-none">
              <button class="btn-red-header">Soy aliado</button>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="btn-whatsapp b2b"
              rel="noopener noreferrer"
              (click)="contactEvent()"
              aria-label="redirección WhatsApp de ventas"
              target="_blank"
              [href]="linkWhatsAppB2B"
            >
              <img
                src="https://res.cloudinary.com/pervolare-org/image/upload/v1705335919/TAS/side-bar/wsp_mu5y4j.svg"
                alt="icon whatsapp"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="container-mobile" [ngbCollapse]="isCollapsed" *ngIf="currentRoute !== '/cotizador-de-proveedores'">
  <div class="container-menu-mobile">
    <div class="menu-mobile">
      <div class="box-menu">
        <span class="title-menu-mobile"
          >MENÚ <b>TAS</b>
          <img
            class="close-menu"
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1698886700/TAS/home/close-menu_jj50gw.svg"
            (click)="isCollapsed = !isCollapsed"
            alt="boton cerrar"
        /></span>
      </div>
      <ngb-accordion [closeOthers]="true" class="text-primary text-center mt-5" id="acordion-menu-mobile">
        <ngb-panel id="menuOne" class="text-center">
          <ng-template ngbPanelTitle>
            <span routerLink="/planes" (click)="isCollapsed = !isCollapsed">Planes de asistencia</span>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="menuTwo" title="Conócenos">
          <ng-template ngbPanelContent>
            <span class="submenu-mobile" routerLink="/nosotros/por-que" (click)="isCollapsed = !isCollapsed"
              >Nosotros</span
            ><br />
            <span class="submenu-mobile" routerLink="/contacto" (click)="isCollapsed = !isCollapsed">Contacto</span
            ><br />
            <a href="{{ urlBlog }}" target="_blank" class="text-decoration-none" (click)="isCollapsed = !isCollapsed"
              ><span class="submenu-mobile">Blog</span></a
            >
          </ng-template>
        </ngb-panel>
        <ngb-panel id="menuThree" title="Ayuda">
          <ng-template ngbPanelContent>
            <span class="submenu-mobile" routerLink="/consulta" (click)="isCollapsed = !isCollapsed"
              >Consulta tu certificado</span
            ><br />
            <span class="submenu-mobile" routerLink="/preguntas-frecuentes" (click)="isCollapsed = !isCollapsed"
              >Preguntas frecuentes</span
            ><br />
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <div class="text-center">
        <button
          class="btn-blue-header mobile mt-5"
          routerLink="/cotizador-de-proveedores"
          (click)="isCollapsed = !isCollapsed"
        >
          Portal agencias
        </button>
        <br />
        <button class="btn-red-header mobile mt-3">
          <a [href]="linkEmergency" target="_blank" class="text-decoration-none">Reportar emergencia</a>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-mobile" [ngbCollapse]="isCollapsed" *ngIf="currentRoute === '/cotizador-de-proveedores'">
  <div class="container-menu-mobile">
    <div class="menu-mobile">
      <div class="box-menu">
        <span class="title-menu-mobile"
          >MENÚ <b>TAS</b>
          <img
            class="close-menu"
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1698886700/TAS/home/close-menu_jj50gw.svg"
            (click)="isCollapsed = !isCollapsed"
            alt="boton cerrar"
        /></span>
      </div>
      <ngb-accordion [closeOthers]="true" class="text-primary text-center mt-5" id="acordion-menu-mobile">
        <ngb-panel id="menuTwo" title="Conócenos">
          <ng-template ngbPanelContent>
            <span class="submenu-mobile" routerLink="/nosotros/por-que" (click)="isCollapsed = !isCollapsed"
              >Nosotros</span
            ><br />
            <span class="submenu-mobile" routerLink="/contacto" (click)="isCollapsed = !isCollapsed">Contacto</span>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <div class="text-center">
        <a [href]="linkFenix" target="_blank" class="text-decoration-none">
          <button class="btn-red-header mobile mt-3">Soy aliado</button>
        </a>
      </div>
    </div>
  </div>
</div>

<div id="ref"></div>
