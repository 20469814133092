import { Component, ElementRef, OnInit, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { ParametersSlidesDto, SlidesDto } from '@app/@core/api/models/parameters.interface';
import { ParameterByDomainService, StorageService } from '@app/@core/services';
import { GoogleAnaliticsService } from '@app/@core/services/analytics/google-analitics.service';
import { MediaEventsService } from '@app/@core/services/media-events/media-events.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { Carousel } from 'primeng/carousel';
@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  //@ViewChild('mobileBanner') private mobileBannerRef!: ElementRef;
  //@ViewChild('banner') private bannerRef!: ElementRef;
  private renderer: Renderer2;
  public linkWhats: string | undefined;
  images: SlidesDto[] | undefined;
  parameterSlides: ParametersSlidesDto | undefined;
  public isVisible = false;

  constructor(
    private renderer2: RendererFactory2,
    public readonly _mediaEvents: MediaEventsService,
    private readonly _parameterDomain: ParameterByDomainService,
    private _analytics: GoogleAnaliticsService,
    private readonly _storage: StorageService,
    config: NgbCarouselConfig,
    private elementRef: ElementRef
  ) {
    Carousel.prototype.onTouchMove = () => {};
    this.renderer = this.renderer2.createRenderer(null, null);
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = true;
    config.wrap = true;
  }

  ngOnInit() {
    const parameters$ = this._parameterDomain.parameter$;
    const width$ = this._mediaEvents.screenWidth$;
    this._parameterDomain.getSlides(this._storage.getTopLevelDomain()).subscribe((slides) => {
      this.parameterSlides = slides.Result;
      combineLatest([parameters$, width$])
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          const width = data[1];
          if (width > 414 && width < 768) {
            this.images = undefined;
            this.images = this.parameterSlides?.tablet;
          } else if (width > 767) {
            this.images = undefined;
            this.images = this.parameterSlides?.desktop;
          } else {
            this.images = this.parameterSlides?.mobile;
          }
        });
    });

    this.getLinkWhatsapp();
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.isVisible = true;
    }, 5000);
  }

  getLinkWhatsapp() {
    this._parameterDomain
      .getInfoFooterByTopLevelDomain(this._storage.getTopLevelDomain())
      .subscribe((infoFooter) => (this.linkWhats = infoFooter?.whatsapp));
  }

  public contactEvent(): void {
    /**Google Analytics Event */
    this._analytics.addEvent('whatsapp');
  }
}
