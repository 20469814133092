import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@app/@core/api/models/base/base-response.dto';
import {
  AdminGetOrderStatusRequestDto,
  CustomerDto,
  TravelInsuranceCompleteInfoRequestDto,
  TravelInsuranceCustomerDto,
  TravelInsuranceQuoteResponseDto,
  TravelInsuranceRequestDto,
  CreateOrderResponseDto,
} from '@core/api/models';
import {
  QuotationService,
  TravelInsuranceRequestCompleteInfoService,
  TravelInsuranceRequestService,
} from '@core/api/services';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CheckoutService {
  isValidForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  validate: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  constructor(
    private _client: HttpClient,
    private readonly _quotation: QuotationService,
    private readonly _complete: TravelInsuranceRequestCompleteInfoService,
    private readonly _travelInsuranceRequest: TravelInsuranceRequestService
  ) {
    this._quotation.rootUrl = environment.services.root;
    this._complete.rootUrl = environment.services.root;
    this._travelInsuranceRequest.rootUrl = environment.services.root;
  }

  getTravelInsurance(HashCode: string, Params: string[]): Observable<TravelInsuranceQuoteResponseDto> {
    return this._quotation
      .apiServicesAppQuotationGetTravelInsuranceRequestQuoteGet$Json(HashCode, Params)
      .pipe(map((data: any) => JSON.parse(data.result) as TravelInsuranceQuoteResponseDto));
  }

  updateTravelInsurance(body: TravelInsuranceRequestDto): Observable<TravelInsuranceRequestDto> {
    return this._travelInsuranceRequest
      .apiServicesAppTravelInsuranceRequestUpdatePut$Json({
        Authentication: '',
        body,
      })
      .pipe(map((data) => data['result'] as TravelInsuranceRequestDto));
  }

  completeInfo(body: TravelInsuranceCompleteInfoRequestDto): Observable<void> {
    return this._complete.apiServicesAppTravelInsuranceRequestCompleteInfoCompleteInfoPost({
      body,
    });
  }

  toggleNewsLetter(id: number, notification: boolean): Observable<void> {
    return this._travelInsuranceRequest.apiServicesAppTravelInsuranceRequestToggleNewsLetterPost({
      Authentication: '',
      body: {
        travelInsuranceRequestId: id,
        notification,
      },
    });
  }

  getOrderStatus(hashCode: string): Observable<AdminGetOrderStatusRequestDto> {
    return this._client
      .get<BaseResponse<AdminGetOrderStatusRequestDto>>(`${environment.serverUrl}/dashboard/OrderStatus/${hashCode}`)
      .pipe(map((res) => res.result));
  }

  updateDiscountCode(hashCode: string, discountCode: string): Observable<string> {
    const body = {
      UserId: environment.userB2C,
      HashCode: hashCode,
      DiscountCode: discountCode,
    };
    return this._client
      .post<BaseResponse<string>>(`${environment.serverUrl}/dashboard/setDiscountCode`, body)
      .pipe(map((res) => res.result));
  }

  //Obtener informacion de orden de pago
  getOrderById(hashCode: number): Observable<TravelInsuranceQuoteResponseDto> {
    return this._client
      .get<BaseResponse<TravelInsuranceQuoteResponseDto>>(`${environment.serverUrl}/Order/GetByIdOrder/${hashCode}`)
      .pipe(map((data: any) => JSON.parse(data.result) as TravelInsuranceQuoteResponseDto));
  }

  //Crear orden de pago
  createOrder(hashCode: number): Observable<CreateOrderResponseDto[]> {
    const body = {
      travelInsuranceRequestId: hashCode,
    };
    return this._client
      .post<BaseResponse<CreateOrderResponseDto[]>>(`${environment.serverUrl}/Order/Order/true`, body)
      .pipe(map((res) => res.result));
  }

  //Actualizar status de orden de pago

  updatePaymentStatus(orderId: number, id: number): Observable<string> {
    const body = {
      travelInsuranceRequestId: id,
      orderId: orderId,
      statusId: 2,
    };
    return this._client
      .put<BaseResponse<string>>(`${environment.serverUrl}/Order/UpdateOrder`, body)
      .pipe(map((res) => res.result));
  }

  //Actualizar pasa orden de pago
  updateCustomers(customers: TravelInsuranceCustomerDto[], orderId: number): Observable<string> {
    const body = {
      temporalData: { Customers: customers },
      orderId: orderId,
      lastModificationUserId: environment.userB2C,
    };
    return this._client
      .put<BaseResponse<string>>(`${environment.serverUrl}/Order/UpdateOrder`, body)
      .pipe(map((res) => res.result));
  }

  //Crear orden de pago
  updatePaymentOrder(id: number, statusId: number, orderId: number): Observable<string> {
    const body = {
      travelInsuranceRequestId: id,
      orderId: orderId,
      statusId: statusId,
    };
    return this._client
      .put<BaseResponse<string>>(`${environment.serverUrl}/Order/UpdatePayment`, body)
      .pipe(map((res) => res.result));
  }
}
