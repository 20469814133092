import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseResponseSearchCert,
  BodyResponseOTP,
  OTPAccessDto,
  OTPVerifyDto,
  SearchCertificateDto,
  SearchCertificateInput,
} from '@app/@core/api/models/search-certificate-input';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchCertificateService {
  constructor(private http: HttpClient) {}

  getCertificateByNumber(body: SearchCertificateInput): Observable<BaseResponseSearchCert<string>> {
    return this.http.post<BaseResponseSearchCert<string>>(
      `${environment.serverUrl}/admin/Adm/CertificateConsultation`,
      body
    );
  }

  getOTPCode(body: OTPAccessDto): Observable<BaseResponseSearchCert<BodyResponseOTP>> {
    return this.http.post<BaseResponseSearchCert<BodyResponseOTP>>(
      `${environment.serverUrl}/admin/Adm/GetAccessCodeCertificate`,
      body
    );
  }

  verifyOTPCode(body: OTPVerifyDto): Observable<BaseResponseSearchCert<boolean>> {
    return this.http.post<BaseResponseSearchCert<boolean>>(`${environment.serverUrl}/admin/Adm/VerifyAccessCode`, body);
  }
}
