<div class="container pt-md-5">
  <div class="row justify-content-center">
    <div class="col-12 title-info">
      <!-- [innerHTML]="'PAGES.HOME.INFO_ASSISTANCE.TRAVEL_ASSISTANCE' | translate" -->
      <h1 class="title-blue text-center mt-4 mb-2 font-size-32 font-size-lg-40">Asistencia de Viaje</h1>
    </div>
    <div class="col-12 col-md-7 col-lg-6">
      <p class="mt-5 mb-3 font-size-24 font-size-md-32 text-blue-tas lh-1">
        <span class="fw-bold">Viaja Seguro</span> a cualquier parte del mundo
      </p>
      <p class="font-size-18 text-justify">
        En Traveler Assistance Services conocemos las necesidades de nuestros viajeros nacionales e internacionales.
        Somos especialistas en realizar planes que se adapten a ti, brindándote tranquilidad y seguridad en cada
        aventura. Te protegemos en caso de una emergencia médica, desde un malestar general hasta un contratiempo que
        requiera hospitalización. Nuestra asistencia en viaje te ofrece beneficios como: cubrimiento por daños o pérdida
        de equipaje, cancelación de vuelo o de viaje, pérdida o robo de documentos y protección de dispositivos
        electrónicos.
      </p>
    </div>
    <div class="col-12 col-md-5 col-lg-6 center-img">
      <img
        class="img-fluid mt-lg-3"
        ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938002/TAS/home/info_assistance_rryw0n_mt54gb.webp"
        alt="img info assistance"
        width="595"
        height="411"
      />
    </div>
  </div>
</div>
<div class="container-fluid mt-3 bg-choose">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-auto title-graphics">
        <p class="text-blue-tas font-size-28 font-size-xl-30 lh-1 fw-bold mb-4 mb-md-4 mb-xl-0">¿Por qué elegirnos?</p>
      </div>
      <div class="col-12 col-md border-choose mb-3 mb-md-0 d-flex">
        <div class="d-block d-md-flex card-choose text-center text-md-start">
          <div class="center-img pe-lg-2">
            <img
              class="img-sise-choose"
              width="45"
              height="42"
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1719938002/TAS/home/atencion_f2wuqb_ynwqx7.webp"
              alt="icon"
            />
          </div>
          <div class="d-inline-block">
            <p class="text-red font-size-lg-25 font-size-xl-25 mb-0">Atención</p>
            <p class="text-blue-tas lh-1 text-separator mb-0 font-size-14 font-size-md-16 text-center text-md-start">
              Comunicación con nuestra central de asistencia 24/7
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md border-choose mb-3 mb-md-0 d-flex">
        <div class="d-block d-md-flex card-choose text-center text-md-start">
          <div class="center-img pe-lg-2">
            <img
              class="img-sise-choose"
              width="45"
              height="42"
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1648064650/TAS/home/Frame1c_psvhqv.svg"
              alt="icon"
            />
          </div>
          <div class="d-inline-block">
            <p class="text-red font-size-lg-25 font-size-xl-25 mb-0">Confianza</p>
            <p class="text-blue-tas lh-1 text-separator mb-0 font-size-14 font-size-md-16 text-center text-md-start">
              Más de tres millones de viajeros confían en nosotros
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md border-choose mb-3 mb-md-0 d-flex">
        <div class="d-block d-md-flex card-choose text-center text-md-start">
          <div class="center-img pe-lg-2">
            <img
              class="img-sise-choose"
              width="45"
              height="42"
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1648064650/TAS/home/Frame3co_znp5ya.svg"
              alt="icon"
            />
          </div>
          <div class="d-inline-block">
            <p class="text-red font-size-lg-25 font-size-xl-25 mb-0">Multidestino</p>
            <p class="text-blue-tas lh-1 text-separator mb-0 font-size-14 font-size-md-16 text-center text-md-start">
              Cobertura en más de cinco mil destinos
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md border-choose mb-3 mb-md-0 d-flex">
        <div class="d-block d-md-flex card-choose text-center text-md-start">
          <div class="center-img pe-lg-2">
            <img
              class="img-sise-choose"
              width="45"
              height="42"
              ngSrc="https://res.cloudinary.com/pervolare-org/image/upload/v1648064651/TAS/home/Frame4li_uuaki8.svg"
              alt="icon"
            />
          </div>
          <div class="d-inline-block">
            <p class="text-red font-size-lg-25 font-size-xl-25 mb-0">Alta Calidad</p>
            <p class="text-blue-tas lh-1 text-separator mb-0 font-size-14 font-size-md-16 text-center text-md-start">
              Contrata tu asistencia sin intermediarios
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
