<form [formGroup]="quoteForm" (ngSubmit)="quote()" class="containe" id="form-quote">
  <div class="row justify-content-center align-items-center form-particular">
    <div class="cotizador" [class.first-step]="quoteCode">
      <div [class]="quoteCode ? 'd-none' : 'd-none d-md-flex header-cot'">
        {{ isB2B == true ? '¡SIMULA AHORA TU ASISTENCIA DE VIAJE!' : '¡COTIZA AHORA TU ASISTENCIA DE VIAJE!' }}
      </div>
      <div class="card p-3">
        <div *ngIf="formParticular" class="row formParticular-exit">
          <button (click)="closeForm()" class="btn">
            <i class="far fa-times-circle"></i>
          </button>
        </div>
        <div class="row header-form mb-2">
          <div [class]="quoteCode ? 'col-12 col-md-2 px-2' : 'w-20-cot w-50-cot px-2'">
            <p class="h4 font-size-12 text-primary mt-1">
              <img
                class="imagen-left"
                src="https://res.cloudinary.com/pervolare-org/image/upload/v1648064650/TAS/formularioPrincipal/Vector_btiznp.svg"
                alt="Icono de avion país de origen"
                title="Icono de avion p de origen"
                width="15px"
                height="15px"
              />
              PAÍS DE ORIGEN
            </p>
            <country-select
              [countries]="location.Visas || []"
              [country]="quoteForm.controls.origin"
              id="origin"
            ></country-select>
          </div>
          <div [class]="quoteCode ? 'col-12 col-md-2 px-2' : 'w-20-cot w-50-cot px-2'">
            <p class="h4 font-size-12 text-primary mt-1">
              <img
                class="imagen-right"
                src="https://res.cloudinary.com/pervolare-org/image/upload/v1648064662/TAS/formularioPrincipal/Group_el7xqj.svg"
                alt="País destino"
                title="País destino"
                width="12px"
                height="auto"
              />
              PAÍS DE DESTINO
            </p>
            <country-select
              [countries]="location.Visas || []"
              [country]="quoteForm.controls.destiny"
              [position]="'right'"
              id="destiny"
            ></country-select>
          </div>
          <div [class]="quoteCode ? 'col-12 col-md-3 px-2' : 'w-40-cot w-100-cot px-2'">
            <quote-date-picker
              [onInit]="$onInit"
              [arrivalDate]="quoteModel.getControl('arrivalDate')"
              [departureDate]="quoteModel.getControl('departureDate')"
            ></quote-date-picker>
          </div>
          <div class="col-12 col-md-1 first-step px-2" *ngIf="quoteCode">
            <p class="h4 font-size-12 text-primary mt-1">DÍAS COTIZADOS</p>
            <input
              [class]="{ 'px-2': isB2B && travelInsurance?.Status }"
              class="form-control form-correo"
              readonly="true"
              [value]="travelInsurance?.TravelLength + ' días'"
            />
          </div>
          <div class="col-12 col-md-2 first-step px-2" *ngIf="quoteCode">
            <p class="h4 font-size-12 text-primary mt-1">N° DE PASAJEROS</p>
            <div
              ngbDropdown
              #myDrop="ngbDropdown"
              class="d-inline-block w-100"
              id="cont-passengers"
              [class.invalid]="this.quoteModel.agesControl.touched && this.quoteModel.agesControl.status == 'INVALID'"
            >
              <button type="button" class="btn btn-passengers w-100" id="passengers" ngbDropdownToggle>
                <i class="fa fa-user-circle" style="color: #004686"></i> Pasajeros
              </button>
              <div ngbDropdownMenu aria-labelledby="passengers">
                <div class="container-fluid row px-3 d-flex align-items-center">
                  <div class="col-6 font-size-14">N° de pasajeros:</div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4 justify-content-center align-items-center d-flex">
                        <button class="btn px-1" type="button">
                          <img
                            src="https://res.cloudinary.com/pervolare-org/image/upload/v1701200529/TAS/home/decrease_fnnhpx.svg"
                            (click)="decrease()"
                          />
                        </button>
                      </div>
                      <div class="col-4 justify-content-center align-items-center d-flex">
                        <input
                          type="text"
                          readonly
                          class="form-control h4 mb-0 font-size-22 input-passengers"
                          [value]="changes2 === 1 ? quoteModel!.agesControl!.controls!.length : '0'"
                        />
                      </div>
                      <div class="col-4 justify-content-center align-items-center d-flex">
                        <button class="btn px-2" type="button">
                          <img
                            src="https://res.cloudinary.com/pervolare-org/image/upload/v1701200529/TAS/home/increase_bmloyx.svg"
                            (click)="increase()"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
                <div
                  *ngIf="changes2 === 1"
                  class="container-fluid mt-3 fadeIn"
                  style="max-height: 100px; overflow: auto"
                >
                  <div formArrayName="ages" class="row">
                    <div
                      *ngFor="let age of quoteModel?.agesControl?.controls; let i = index"
                      class="col-12 px-2 input-age d-flex align-items-center"
                    >
                      <div class="col-6">Edad pasajero {{ i + 1 }}:</div>
                      <div class="col-6">
                        <input
                          [formControlName]="i"
                          type="number"
                          min="0"
                          class="w-100 border-2 text-center mb-2 text-primary age font-size-18"
                          id="pass{{ i }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 align-items-center d-flex justify-content-center pb-2">
                  <button
                    type="button"
                    class="btn mt-2 btn-consulta-success pt-2 pb-2 font-size-14 w-75"
                    (click)="myDrop.close()"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 px-2 mb-4 d-block d-md-none" *ngIf="quoteCode">
            <p class="h4 font-size-12 text-primary mt-1">CÓDIGO DE DESCUENTO</p>
            <input
              id="discountCode"
              type="text"
              formControlName="discountCode"
              class="form-control form-discount"
              placeholder="Cod Descuento"
            />
          </div>
          <div
            [class]="
              quoteCode
                ? 'col-12 col-md-2 px-2 d-block align-items-center'
                : 'w-20-cot px-2 d-md-flex d-none align-items-center'
            "
          >
            <ng-container *ngIf="!(isB2B && travelInsurance?.Status); else cleanQuoteB2B">
              <p
                class="h4 font-size-12 text-primary mt-1 d-none d-md-block"
                style="cursor: pointer"
                *ngIf="quoteCode"
                (click)="showDiscount = true"
              >
                <u>CÓDIGO DE DESCUENTO</u>
              </p>
              <div class="col-12 px-2 mb-4 d-none d-md-block float-discount" *ngIf="quoteCode && showDiscount">
                <input
                  id="discountCode"
                  type="text"
                  formControlName="discountCode"
                  class="form-control form-discount"
                  placeholder="Cod Descuento"
                  (blur)="showDiscount = false"
                />
              </div>
              <button
                id="quote"
                type="submit"
                class="btn btn-consulta-success pt-2 pb-2 font-size-14 w-100"
                [class.mt-3]="!quoteCode"
                [disabled]="loading"
                [ngClass]="this.quoteForm.dirty ? 'btn-rojo' : 'btn-grey'"
              >
                <span class="d-none d-md-block" *ngIf="!loading">{{
                  travelInsurance?.Status ? 'MODIFICAR DATOS' : 'COTIZAR GRATIS'
                }}</span>
                <span class="d-block d-md-none" *ngIf="!loading">{{
                  travelInsurance?.Status ? 'CONFIRMAR MODIFICACIÓN' : 'COTIZAR GRATIS'
                }}</span>
                <app-loader
                  [isLoading]="loading"
                  [message]="travelInsurance?.Status ? 'RECALCULANDO' : 'CALCULANDO'"
                ></app-loader>
              </button>
            </ng-container>

            <ng-template #cleanQuoteB2B>
              <button
                id="quote"
                type="submit"
                class="btn btn-requote pt-2 pb-2 font-size-17 w-100 btn-rojo mt-3"
                [disabled]="loading"
              >
                <span *ngIf="!loading">{{ travelInsurance?.Status ? 'RECALCULAR' : 'COTIZAR GRATIS' }}</span>
                <app-loader
                  [isLoading]="loading"
                  [message]="travelInsurance?.Status ? 'RECALCULANDO' : 'CALCULANDO'"
                ></app-loader>
              </button>
              <!--<button
                (click)="newQuoteB2B()"
                type="button"
                class="btn btn-primary btn-block mt-2 py-2 px-5 w-75"
                style="font-weight: 600"
              >
                COTIZAR DE NUEVO
              </button>-->
            </ng-template>
          </div>
        </div>
        <div *ngIf="(changes2 == 1 && !quoteCode) || isB2B" class="row header-form fadeIn pb-2">
          <div class="w-20-cot w-100-cot px-2 pb-1" *ngIf="!quoteCode">
            <p class="h4 font-size-12 text-primary mt-1">N° DE PASAJEROS</p>
            <div
              ngbDropdown
              #myDrop="ngbDropdown"
              class="d-inline-block w-100"
              id="cont-passengers"
              [class.invalid]="this.quoteModel.agesControl.touched && this.quoteModel.agesControl.status == 'INVALID'"
            >
              <button type="button" class="btn btn-passengers w-100" id="passengers" ngbDropdownToggle>
                <i class="fa fa-user-circle" style="color: #004686"></i> Pasajeros
              </button>
              <div ngbDropdownMenu aria-labelledby="passengers">
                <div class="container-fluid row px-3 d-flex align-items-center">
                  <div class="col-6 font-size-14">N° de pasajeros:</div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4 justify-content-center align-items-center d-flex">
                        <button class="btn px-1" type="button">
                          <img
                            src="https://res.cloudinary.com/pervolare-org/image/upload/v1701200529/TAS/home/decrease_fnnhpx.svg"
                            (click)="decrease()"
                          />
                        </button>
                      </div>
                      <div class="col-4 justify-content-center align-items-center d-flex">
                        <input
                          type="text"
                          readonly
                          class="form-control h4 mb-0 font-size-22 input-passengers"
                          [value]="changes2 === 1 ? quoteModel!.agesControl!.controls!.length : '0'"
                        />
                      </div>
                      <div class="col-4 justify-content-center align-items-center d-flex">
                        <button class="btn px-2" type="button">
                          <img
                            src="https://res.cloudinary.com/pervolare-org/image/upload/v1701200529/TAS/home/increase_bmloyx.svg"
                            (click)="increase()"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
                <div
                  *ngIf="changes2 === 1"
                  class="container-fluid mt-3 fadeIn"
                  style="max-height: 100px; overflow: auto"
                >
                  <div formArrayName="ages" class="row">
                    <div
                      *ngFor="let age of quoteModel?.agesControl?.controls; let i = index"
                      class="col-12 px-2 input-age d-flex align-items-center"
                    >
                      <div class="col-6">Edad pasajero {{ i + 1 }}:</div>
                      <div class="col-6">
                        <input
                          [formControlName]="i"
                          type="number"
                          min="0"
                          class="w-100 border-2 text-center mb-2 text-primary age font-size-18"
                          id="pass{{ i }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 align-items-center d-flex justify-content-center pb-2">
                  <button
                    type="button"
                    class="btn mt-2 btn-consulta-success pt-2 pb-2 font-size-14 w-75"
                    (click)="myDrop.close()"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-20-cot w-100-cot px-2 pb-1" *ngIf="!quoteCode">
            <p class="h4 font-size-12 text-primary mt-1">EMAIL</p>
            <input
              id="email"
              type="email"
              formControlName="email"
              [class]="{ 'px-2': isB2B && travelInsurance?.Status }"
              class="form-control form-correo"
              placeholder="E-mail"
            />
          </div>
          <div class="w-20-cot w-100-cot px-2 pb-1" *ngIf="!quoteCode && !isB2B">
            <p class="h4 font-size-12 text-primary mt-1">CELULAR</p>
            <div class="form-group quote-telefono">
              <country-select
                *ngIf="showIntel"
                [countries]="indicatives || []"
                [country]="quoteForm.controls.intl"
                id="intl"
              ></country-select>
              <input
                id="phone"
                type="tel"
                formControlName="phone"
                min="0"
                [class]="!showIntel ? 'w-100' : 'w-80'"
                class="form-control form-telefono"
                placeholder="Teléfono"
              />
            </div>
          </div>
          <div class="w-20-cot w-100-cot px-2 pb-1" *ngIf="!quoteCode && !isB2B">
            <p class="h4 font-size-12 text-primary mt-1">CÓDIGO DE DESCUENTO</p>
            <input
              id="discountCode"
              type="text"
              formControlName="discountCode"
              class="form-control form-discount"
              placeholder="Cod Descuento"
            />
          </div>
          <div class="w-40-cot w-100-cot px-2 pb-1" *ngIf="isB2B">
            <p class="h4 font-size-12 text-primary mt-1">PLAN A COTIZAR</p>
            <select class="form-control" id="form-plan" placeholder="Elegir plan" formControlName="planId">
              <option [value]="plan.Id" *ngFor="let plan of plans$ | async">
                {{
                  plan.Name +
                    ' - Cobertura hasta ' +
                    (plan.Coverage | number : '1.0-0') +
                    ' ' +
                    plan.CurrencyDescription
                }}
              </option>
            </select>
          </div>
          <div
            class="w-20-cot w-50-cot font-size-13 p-3 text-center text-primary bolder align-items-center d-none d-md-flex"
            *ngIf="!quoteCode"
          >
            <p class="m-0">
              *Al cotizar estás aceptando nuestros <a href="/condiciones-de-uso" target="_blank">T&C</a> y
              <a href="/politicas-de-privacidad" target="_blank">ATH</a>
            </p>
          </div>
        </div>
        <div class="col-12 col-md-3 px-2 py-2 d-md-none d-flex align-items-center" *ngIf="!quoteCode">
          <ng-container *ngIf="!(isB2B && travelInsurance?.Status); else cleanQuoteB2B">
            <p
              class="h4 font-size-12 text-primary mt-1 d-none d-md-block"
              style="cursor: pointer"
              *ngIf="quoteCode"
              (click)="showDiscount = true"
            >
              <u>CÓDIGO DE DESCUENTO</u>
            </p>
            <div class="col-12 px-2 mb-4 d-none d-md-block float-discount" *ngIf="quoteCode && showDiscount">
              <input
                id="discountCode"
                type="text"
                formControlName="discountCode"
                class="form-control form-discount"
                placeholder="Cod Descuento"
                (blur)="showDiscount = false"
              />
            </div>
            <button
              id="quote"
              type="submit"
              class="btn btn-consulta-success pt-2 pb-2 font-size-14 w-100"
              [disabled]="loading"
            >
              <span class="d-none d-md-block" *ngIf="!loading">{{
                travelInsurance?.Status ? 'MODIFICAR DATOS' : 'COTIZAR GRATIS'
              }}</span>
              <span class="d-block d-md-none" *ngIf="!loading">{{
                travelInsurance?.Status ? 'CONFIRMAR MODIFICACIÓN' : 'COTIZAR GRATIS'
              }}</span>
              <app-loader
                [isLoading]="loading"
                [message]="travelInsurance?.Status ? 'RECALCULANDO' : 'CALCULANDO'"
              ></app-loader>
            </button>
          </ng-container>

          <ng-template #cleanQuoteB2B>
            <button
              id="quote"
              type="submit"
              class="btn btn-requote pt-2 pb-2 font-size-17 w-100 btn-rojo mt-3"
              [disabled]="loading"
            >
              <span *ngIf="!loading">{{ travelInsurance?.Status ? 'RECALCULAR' : 'COTIZAR GRATIS' }}</span>
              <app-loader
                [isLoading]="loading"
                [message]="travelInsurance?.Status ? 'RECALCULANDO' : 'CALCULANDO'"
              ></app-loader>
            </button>
            <!--<button
              (click)="newQuoteB2B()"
              type="button"
              class="btn btn-primary btn-block mt-2 py-2 px-5 w-75"
              style="font-weight: 600"
            >
              COTIZAR DE NUEVO
            </button>-->
          </ng-template>
        </div>
        <div
          class="col-12 font-size-13 pb-2 text-center text-primary bolder align-items-center d-md-none d-block"
          *ngIf="changes2 == 1 && !quoteCode"
        >
          <p class="m-0">
            *Al cotizar estás aceptando nuestros <a href="/condiciones-de-uso" target="_blank">T&C</a> y
            <a href="/politicas-de-privacidad" target="_blank">ATH</a>
          </p>
        </div>
        <div *ngIf="changes2 == 1" class="row justify-content-center align-items-center pb-1 fadeIn">
          <!--<div class="col-12 col-md-12 font-size-13 text-center text-primary bolder">
            <p class="m-0">
              *Al cotizar estás aceptando nuestros <a href="/condiciones-de-uso" target="_blank">T&C</a> y
              <a href="/politicas-de-privacidad" target="_blank">ATH</a>
            </p>
          </div>
          <div class="row justify-content-center text-center pb-1" *ngIf="isB2B">
            <div class="col-12">
              <a class="font-size-13 text-primary bolder" target="_blank" href="{{ linkFenix }}">
                Sistema de Emisiones
              </a>
            </div>
          </div>-->

          <!-- Summary B2B -->
          <div *ngIf="isB2B && travelInsurance?.Status" class="col-12 pt-4 px-2">
            <div>
              <div class="row summary-b2b">
                <div class="w-40-cot info-plan" *ngIf="planCotizado">
                  <p class="text-primary fw-bolder">Plan Cotizado</p>
                  <p>
                    {{ loading ? 'XXXX' : namePlan }} - Cobertura hasta
                    <b>{{ CoveragePlan | number : '1.0-0' }} {{ CurrencyDescriptionPlan?.toUpperCase() }}</b>
                  </p>
                </div>

                <div class="w-20-cot info-detail" *ngIf="planCotizado">
                  <ul class="text-primary text-start ps-0 font-size-14">
                    <li><b>Días de cobertura:</b> {{ loading ? 'XXXX' : travelInsurance!.TravelLength }}</li>
                    <li><b>Límite de edad:</b> {{ loading ? 'XXXX' : AgeLimit }}</li>
                    <li><b>Tarifa:</b> USD {{ loading ? 'XXXX' : planPrice || 1 }}</li>
                    <li>
                      <b>Valor Unitario:</b> USD
                      {{ loading ? 'XXXX' : (planPrice || 1) / (travelInsurance!.Customers?.length || 1) }}
                    </li>
                  </ul>
                </div>
                <div class="w-20-cot btn-b2b" *ngIf="planCotizado">
                  <button
                    (click)="sendMailB2b()"
                    type="button"
                    class="btn btn-consulta-success btn-rojo btn-block text-uppercase font-size-13 font-size-lg-16 py-3 w-100"
                  >
                    Enviar por email
                  </button>
                </div>
                <div class="w-20-cot btn-b2b" *ngIf="planCotizado">
                  <a
                    [href]="'/insurance-documents/Quote?hashcode=' + hashCode + '&pax=1' + '&Quotation=Quotation'"
                    target="_blank"
                    class="btn btn-consulta-success btn-rojo btn-block text-uppercase font-size-13 font-size-lg-16 w-100 py-3"
                    >descargar pdf</a
                  >
                </div>
                <div *ngIf="!planCotizado" class="col-12 mt-3">
                  <small class="text-danger text-justify"
                    >El plan seleccionado no se encuentra disponible con los datos especificados.</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
